
@keyframes arrow-jump {
  0%   { opacity: 0;}
  100% { opacity: 1; 
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    }  
}

html {
  scroll-behavior: smooth; 
}

h1, h2, h3, h4, h5, h5 {
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
}

p, a, label, span, li {
  font-family: "Lato" !important;
}

.info-list {
  text-align: left;
  width: 85%;
  margin: auto;
  font-size: 18px;
}

.infoListItem {
  margin-bottom: 5px;
}

.sectionHeader {
  font-size: 48px;
  margin: auto;
  text-align: center;
  width: 96%;
}

.sectionSubheader {
  font-size: 32px;
  margin: auto;
  text-align: center;
  width: 98%;
}

.sectionCaption {
  font-size: 24px;
  width: 90%;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}

.section {
  height: 850px;
  max-height: 850px;
}

.medium-section {
  height: 1000px;
}

.long-section {
  height: 1200px;
}

.blog-section * {
    font-weight: bold;
}

.bodyText {
  margin: auto;
  width: 85%;
}

.largeBodyText {
  margin: auto;
  width: 85%;
  font-size: 17px;
}

a {
  color: white
}

.pageContainer {
  padding-top: 100px;
  color: white;
}

.redBackground {
  background-color: #a7171a;
}

.light-blue-background {
  background-color: #3d7dca;
}

.sand-background {
  background-color: #ca8a3d;
}

.dan-banner-background {
  background-image: url('./images/dansquared-phone-background.png');
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; 
}

.linkNoDecoration {
  color: rgb(0, 0, 0, 0.87);
  text-decoration: none;
}

.center-text {
  text-align: center;
}

.socialIconContainer {
  flex-basis: 20%;
  text-align: center;
  margin: auto;
}

.socialIconLink {
  color: white;
}

.marginTop {
  margin-top: 25px !important;
}

.marginBottom {
  margin-bottom: 25px;
}

.paddingBottom {
  padding-bottom: 55px;
}

.button {
  font-size: 2rem !important;
  margin: 50px !important;
  /* background-color: #a7171a !important; */
}

.text-background {
  background: lightgray;
  background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
  background: rgba(61, 61, 61, 0.9);
}

.rounded-face-image {
  width: 50%;
  max-width: 230px;
  border-radius: 50%;
}

.movingArrow {
  animation: arrow-jump 1s infinite;
  color: #fafafa;
}


/*  TODO move these styles to their own file */

.card {
  margin: auto;
  max-width: 350px;
}